import React, { Component } from "react"
import PropTypes from "prop-types"

import * as styles from "./style.module.css"


class FAQEntry extends Component {
  state = {
    open: false
  };
  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
  	return (
		  	<div class={this.state.open? styles.entry+" "+styles.faqoen : styles.entry+" "+styles.faqclose}>
		  		<div role="button" tabIndex='0' class={'color-text-primary font-style-bold '+styles.bullet} onClick={this.handleClick} onKeyDown={this.handleClick}>
		  			{this.state.open? "-" : "+"}
		  		</div>
		      {this.props.children}
		  	</div>
  		);
  }

}


FAQEntry.propTypes = {
  children: PropTypes.node,
};

export default FAQEntry
