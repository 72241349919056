import React from "react"

import Layout from "../components/layout"
import HeaderOffset from "../components/header/headeroffset"

import FAQEntry from "../components/faqentry"
import * as styles from "../styles/pages/faq.module.css"

import image_divider from '../images/divider.jpg'

const FAQPage = () => (
  <Layout activePath='faq' pageTitle="FAQs">
	<HeaderOffset />
	<div class="margin-content">
		<h2 class="font-size-large text-centered color-text-primary">
			TECHNICAL
		</h2>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">What is the life expectancy of the Artilin<span className='registeredicon'>&reg;</span> Anti-Insect Technology?</h3>
	  		<p>
	  			Since the active ingredient stays within the paint film, it should remain effective almost as long as the service life of the paint under normal conditions. The rate at which the insecticidal crystals are used is directly proportional to the number of insects that make contact with the painted surface.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">How can I maintain the efficacy of the paint over time?</h3>
	  		<p>
	  			Do not clean the painted surface within 7 days of application. To maximize the efficacy of BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade;, lightly clean painted surface with a damp, non-abrasive cloth to remove dust. Do not use soap or detergents.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">How long would it take to kill insects that land on a BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; painted surface?</h3>
	  		<p>
	  			When an insect lands on a BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; painted surface, the microcrystals are transmitted to the nervous system of the insect, leading to its death after a short time. The length of time depends on the size of the insect and how long it stays on the BUG OFF&trade; painted surface.
	  		</p>
			<br/>
	  		<p>
	  			In lab tests, BUG OFF&trade; paints inflicted 100% mortality against mosquitoes, American cockroaches, and common houseflies after 24 hours of exposure to a BUG OFF&trade; painted substrate.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Is BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; effective on ants, spiders, moths, and other kinds of insects?</h3>
	  		<p>
	  			While no tests have been conducted on ants and spiders, surfaces painted with BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; have been shown to repel the presence of ants and spider webs.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Does BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; give off a strong odor associated with insecticides of paint? Does BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; require a longer drying and ventilation period?</h3>
	  		<p>
	  			BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; is not completely odor-less. The ventilation needed during the drying time is the same as ordinary emulsion paint application. Make sure that the paint has thoroughly dried and the painted room has been properly ventilated before moving in.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Can I just use BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; as an accent wall?</h3>
	  		<p>
	  			Yes, but overall efficacy may be decreased due to a smaller painted surface area. To maximize BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade;, use it on bigger and wider surfaces where insects such as cockroaches, mosquitoes, and houseflies are known to rest or crawl through.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">If there is already an existing paint on the wall, would it be okay to just put one coat of BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; instead of the recommended two coats?</h3>
	  		<p>
	  			Yes, one coat of BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; is already effective as an anti-insect paint. However, the physical appearance of the paint may be sacrificed upon doing so since 2 coats of paint is usually required.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Can BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; be painted on other surfaces such as floors and furniture?</h3>
	  		<p>
	  			BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; paint is only intended for concrete walls, drywalls, ceilings, and properly primed wooden walls.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Are any special equipment or sundries needed in the application of BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade;?</h3>
	  		<p>
	  			Apply BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; paint using rollers or brushes. Do not apply product by spray.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Is BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; available in a clear coat variant?</h3>
	  		<p>
	  			No, BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; is not available in a clear coat variant.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">When does the anti-insect technology become effective?</h3>
	  		<p>
	  			The Artilin<span className='registeredicon'>&reg;</span> Anti-Insect Technology becomes effective the moment the paint dries. The insect would then need to come in contact with a BUG OFF&trade; painted surface for a period of time to begin working.
	  		</p>
	  	</FAQEntry>
	</div>
	<img alt='' src={image_divider} class={`fullblock`}/>
	<div class={"margin-content "+styles.lastcontent}>
		<h2 class="font-size-large text-centered color-text-primary">
			AVAILABILITY
		</h2>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Where can I purchase BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade;?</h3>
	  		<p>
	  			As of date, BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; is only available in major home depots in Metro Manila. We will update all online platforms once it becomes available nationwide.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 class="font-size-regular">Are there any available colors for BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade;?</h3>
	  		<p>
	  			As of date, BOYSEN<span class="registeredicon">&reg;</span> BUG OFF&trade; is only available in white. However, being a water-based latex paint, it can be mixed with BOYSEN<span class="registeredicon">&reg;</span> Latex Colors at a maximum level of 1/4 Liter per 4-Liter can.
	  		</p>
			<br/>
	  		<p>
				Take note that B-1466 Hansa Yellow and B-1407 Toluidine Red are for interior painting only. For exterior applications, use B-1469 Exterior Yellow and B-1479 Exterior Red for exterior applications.
	  		</p>
	  	</FAQEntry>
	</div>
  </Layout>
)

export default FAQPage
